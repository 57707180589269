import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { RootStore } from "../Store";
import { runInAction } from "mobx";

export class SignalrClient {
    private hubConnection: HubConnection = null;
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    get connection() {
        return this.hubConnection;
    }

    restartConnection = async (): Promise<HubConnection> => {
        await this.closeConnection();
        return await this.openConnection();
    }

    openConnection = async (): Promise<HubConnection> => {
        if (this.hubConnection !== null &&
            this.hubConnection.state !== HubConnectionState.Disconnected &&
            this.hubConnection.state !== HubConnectionState.Disconnecting) {
            return this.hubConnection;
        }

        const caseId = this.rootStore.casesStore.caseId;
        if (caseId == null) {
            throw new Error("Can't initialize SignalR connection - CaseId is not defined");
        }

        const hubConnection = await this.setUpSignalRConnection("casesHub", caseId);
        runInAction(() => {
            this.hubConnection = hubConnection;
        });
        return this.hubConnection;
    }

    closeConnection = async () => {
        await this.hubConnection?.stop();
        this.hubConnection = null;
    }

    private setUpSignalRConnection = async (relativeHubUrl: string, caseid: number): Promise<HubConnection> => {
        var apiAddress = this.rootStore.casesStore.requestUrl;
        const connection = new HubConnectionBuilder()
            .withUrl(new URL(relativeHubUrl,apiAddress).href)
            .withAutomaticReconnect()
            .build();

        await connection.start();
        await connection.invoke('SubscribeToCaseUpdater', caseid);
        connection.onreconnected(async () => {
            await connection.invoke('SubscribeToCaseUpdater', caseid);
        })

        return connection;
    };
}
